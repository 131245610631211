import { useEffect, useRef } from "react";
import Button from "../../../components/button";
import SectionTitle from "../../../components/section-title";
import Parallax from "parallax-js";

const HomeAboutContainer = () => {
    const sceneEl = useRef(null);

    useEffect(() => {
        const parallaxInstance = new Parallax(sceneEl.current, {
            relativeInput: true,
        });

        parallaxInstance.enable();

        return () => parallaxInstance.disable();
    }, []);
    return (
        <div className="about-us position-relative">
            <div className="container">
                <div className="row mb-n7">
                    <div className="col-xl-5 col-lg-6 mb-7">
                        <div className="about-content">
                            <SectionTitle
                                classOption="title-section"
                                subTitle="ABOUT US"
                                title="We foresee  the 
                                <span class='text-primary'>
                               FUTURE
                                </span>
                                "
                            />
                            {/* We foresee the future, we originate ideas, 
                            we befriend corporate challenges & we create the unbelievable. */}
                            <span className="date-meta">
                                Scince 2018 <span className="hr"></span>
                            </span>
                            <p className="mb-5">
                                We foresee the future, we originate ideas, we
                                befriend corporate challenges & we create the
                                unbelievable
                            </p>
                            {/* <p className="high-light mb-8">
                                An organization with some of the most
                                intellectual minds working together, Snowprogrammers
                                believes in - “inventing advanced technologies &
                                innovating them further to invent more.” We
                                engineer smart IT & BPM solutions that
                                understand, meet and deliver business
                                expectations of our client base. Merging
                                together our years of expertise and ambitious
                                strategies, we serve the ever evolving corporate
                                world with services like Blockchain, Digital
                                Transformation, Zohoo CRM, Unity & much more.
                            </p> */}
                            <Button
                                classOption="btn btn-lg btn-dark btn-hover-dark"
                                text="Learn more"
                                path="/about"
                            />
                        </div>
                    </div>
                    <div className="col-xl-6 order-first order-lg-last col-lg-6 mb-7 offset-xl-1">
                        <div
                            className="about-photo scene text-center text-lg-left"
                            id="scene"
                            ref={sceneEl}
                        >
                            <div data-depth="0.2">
                                <img
                                    src={`${process.env.PUBLIC_URL}/images/about/1.png`}
                                    alt="about"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <img
                className="about-shape"
                src={`${process.env.PUBLIC_URL}/images/about/shape.png`}
                alt="bg-shape"
            />
        </div>
    );
};

export default HomeAboutContainer;
